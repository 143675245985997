import {motion, AnimatePresence} from 'framer-motion';

import styles from './HintMessage.module.scss';

const HintMessagge = ({children, type="error", style, testId="", show=true}) => {

    return ( 

        <AnimatePresence>
            {show &&
            <motion.div className={`${styles.hintMessage} ${styles[`hintMessage-${type}`]}`} style={style} data-testid={testId} initial={{y:-10, height: 0, opacity: 0}} animate={{y: 0, height:"fit-content", opacity: 1}} exit={{y:-10, height:0, opacity: 0, transition:{duration: .2}}} >
                {type === 'error' &&
                '* '
                } 
                {children}
            </motion.div>
            }
        </AnimatePresence>
     );
}
 
export default HintMessagge;