import { motion } from 'framer-motion';

import styles from './AuthLayout.module.scss';

const AuthLayout = ({children, title}) => {
    return (
        <>

            <main className={styles.authLayout}>
                <div className={styles.authLayoutInnerContainer}>
                    <div className={styles.titleSection}>
                        <div style={{width: "100%", display: "flex", justifyContent: "flex-start"}}>
                            <motion.div className={styles.divider} initial={{width: 0}} animate={{width: "100%"}} transition={{ delay: .3, duration: .3 }}></motion.div>
                        </div>
                        <div>
                            <h1>{title}</h1>
                        </div>
                        <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                            <motion.div className={styles.divider} initial={{width: 0}} animate={{width: "100%"}} transition={{ delay: .3, duration: .3 }}></motion.div>
                        </div>
                    </div>

                    <div className={styles.childrenContainer}>
                        {children}
                    </div>
                    
                </div>
            </main>

        </>
    );
}
 
export default AuthLayout;