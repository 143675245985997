import { useEffect, useRef, useState } from 'react';

import styles from './Input.module.scss';

const Input = ({theme="white", as="input", disabled=false, value, onChange, autoFocus=false, placeholder="", type="text", width="", hideLabel=false, testId=null, maxLength=Infinity, pattern=false, name="", autoComplete="", style={}, valid=true, id=""}) => {

    const Field = as

    const inputStyle = {
        width,
    }
    const inputRef = useRef()
    const [focus, setFocus] = useState()
    
    
    useEffect(()=>{
        function handleAutoFocus(){
            ((value === "" || value === undefined) && autoFocus) && inputRef.current.focus()
        }
        handleAutoFocus()
    }, [])

    function handleOnChange(value){
        if(!pattern){
            onChange(value)
        }else{
            new RegExp(pattern).test(value) && onChange(value)
        }
    }
    
    return ( 
        
        <div className={`${styles.input} ${disabled ? styles["input-disabled"] : ""} ${focus ? styles["input-focus"] : ""} ${hideLabel ? styles["input-hideLabel"] : ""} ${styles[`input__field-${theme}`]} ${styles[`input-${as}`]} ${!valid ? styles['input-invalid'] : styles['input-valid']}`} onClick={()=>inputRef.current.focus()} style={style} data-component="Input">

            <Field 
            ref={inputRef}
            type={type} 
            style={inputStyle}  
            className={`${styles.input__field}`} 
            onChange={(e)=>{String(e.target.value).length<=maxLength && handleOnChange(e.target.value)}} 
            onFocus={()=>setFocus(true)}
            onBlur={()=>setFocus(false)}
            value={value}
            name={name}
            autoComplete={autoComplete}
            disabled={disabled}
            data-testid={testId}
            maxLength={maxLength}
            id={id}
            />

            {(hideLabel && (focus || value !== "")) ?
                <></>
            :
            <div className={`${styles.input__placeholder} ${(focus || value) ? styles["input__placeholder-focused"] : ""}`}>{placeholder}</div>
            }

        </div>
     );

}
 
export default Input;