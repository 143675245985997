import { useState } from 'react';
import * as Sentry from "@sentry/nextjs";
import { useRouter } from 'next/router';
import {motion} from 'framer-motion';
import { useAppContext } from 'contexts/AppContext';
import { login, getUserStatus, sendAccountSuspended } from 'api';
import { tryGetUserStatus } from 'utils/index';
import Head from 'next/head';
import AuthLayout from '@/components/layouts/AuthLayout/AuthLayout';
import Link from 'next/link';
import Input from '@/components/elements/Input/Input';
import Button from '@/components/elements/Button/Button';
import HintMessage from '@/components/elements/HintMessage/HintMessage';
import styles from '../styles/Login.module.scss'

const Login = ({ from }) => {   
    
    const router = useRouter();
    const { appLoading, setAppLoading, isSuspended, setIsSuspended, isTerminated, setIsTerminated } = useAppContext();
    const [userAccount, setUserAccount] = useState({email: "", password: ""})
    const [hint, setHint] = useState({message: "", type:""})

    function redirectLoggedInUser(){
        setAppLoading(true)
        if(from){
            window.location.href = from
        }else{
            router.push("/watch")
        }
    }

    async function handleLogin(){
        setAppLoading(true)
        try{
            const res = await login({email: userAccount.email, password: userAccount.password})
            if(res.data.status === "success"){
                const checkSuspendedTerminated = await getUserStatus();
                if (checkSuspendedTerminated.data.aStatus === "suspended"){
                    setIsSuspended(true);
                    router.push("/account?edit=payment+info");
                    return;
                } else if (checkSuspendedTerminated.data.aStatus === "terminated"){
                    setIsTerminated(true);
                    router.push("/account?edit=payment+info");
                    return;
                }
                redirectLoggedInUser()
            }else{
                setHint({type: "error", message: "Login failed. Please make sure your information is correct."})
            }
        }catch(e){
            Sentry.captureException(e);
            let message = "An error occured logging in.";
            console.log('Error: ', e.message)
            if(e?.response?.status === 429){
                message = "Maximum requests exceded. Please try again later."
            }
            setHint({type:"error", message})
        }
        setAppLoading(false)
    }

    return (
        <>
            <Head>
                <title>Login | afterglow</title>
            </Head>
            <AuthLayout title="Login">
                <div className={styles.login}>
                    <form onSubmit={(e)=>{e.preventDefault(), handleLogin()}}>
                        <div className={styles.inputsSection}>
                            <motion.div className={styles.inputs} initial={{y: 20, opacity: 0}} animate={{y:0, opacity: 1}} transition={{ delay: .3, duration: .35 }} >
                                <Input type="email" autoFocus={true} value={userAccount.email} placeholder="Type your email" onChange={(email) => setUserAccount({...userAccount, email})} disabled={appLoading} />
                                <Input type="password" value={userAccount.password} placeholder="Type your password" onChange={(password) => setUserAccount({...userAccount, password})} disabled={appLoading} />
                                <div className={styles.hint}>
                                    <HintMessage type={hint.type} show={hint.message!==""}>
                                        {hint.message}
                                    </HintMessage>
                                </div>
                            </motion.div>
                            <motion.div className={styles.alternativeLinks} initial={{y: 20, opacity: 0}} animate={{y:0, opacity: 1}} transition={{ delay: .5, duration: .35 }}>
                                <Link href="/signup" passHref>
                                    <div>
                                        not a member? click here to sign up
                                    </div>
                                </Link>
                                <Link href="/forgot-password" passHref><div>forgot password?</div></Link>
                            </motion.div>
                            <motion.div initial={{y: 20, opacity: 0}} animate={{y:0, opacity: 1}} transition={{ delay: .7, duration: .35 }}>
                                <Button type="submit" style={{padding: "10px 100px"}} active={!appLoading}>Login</Button>
                            </motion.div>
                        </div>
                    </form>
                </div>
            </AuthLayout>
        </>
     );
}

export async function getServerSideProps(context) {
    const res = await tryGetUserStatus(context)
    const from = context.query.from
    if(res?.data?.loggedIn){
        return {
            redirect: {
                permanent: false,
                destination: res?.data?.isActive ? from ? from : "/watch" : "/signup",
            },
            props:{},
        };
    }
    return {props:{
        from: from ? from : false
    }}
}

export default Login;